.widgetContainer {
  display: flex;
  padding: 10px 20px 0 20px;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.widgetContainer > p {
  font-size: 16px;
  padding: 0;
  position: relative;
  line-height: 20px;
  max-width: 60%;
}
.widgetContainer > p:first-of-type::before {
  content: "Shipping Address:";
  display: block;
  position: absolute;
  font-size: 12px;
  top: -18px;
  white-space: nowrap;
}
.changeButton {
  background-color: rgb(1, 34, 65);
  padding: 12px 20px;
  border-radius: 5px;
  color: white;
  text-align: center;
  border: none;
  font-family: "lindeFontReg";
  font-size: 16px;
}
