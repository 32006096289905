.loading {
  background-image: url("../src/assets/images/background.jpg");
  background-size: cover;
  --height: 20%;
  font-size: 2.5ch;
  justify-content: center;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  font-family: lindeFontReg;
}

#main-content {
  height: 100%;
  width: 100%;
  --background: none;
  background-image: url("../src/assets/images/background.jpg");
  background-size: cover;
  position: fixed;
  overflow-y: scroll;
}

#toolbar {
  background: rgb(0, 24, 46);
}

#logo {
  padding: 10px;
  color: white;
  font-style: italic;
}

#logo img {
  height: 48px;
}

.flex {
  display: flex;
}

.jcc {
  justify-content: center;
}

.ai_s {
  align-content: start;
}

.aic {
  align-items: center;
}

input {
  --placeholder-color: rgb(1, 34, 65, 1);
  font-family: lindeFontReg;

}