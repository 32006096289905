.form-elements {
  position: relative;
}

.form-element {
  justify-self: center;
  padding: 6px;
}

@media screen and (max-height: 647px) {
  .form-elements {
    padding: 10px;
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 10px 15px;
    border-radius: 5px;
    background: white;
  }
}

@media screen and (min-height: 647px) {
  .form-elements {
    padding: 10px;
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 10px 15px;
    border-radius: 5px;
    background: white;
  }
}

@media screen and (min-height: 672px) {
  .form-elements {
    padding: 10px;
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 20px 15px;
    border-radius: 5px;
    background: white;
  }
}

@media screen and (min-height: 690px) {
  .form-elements {
    padding: 10px;
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 20px 15px;
    border-radius: 5px;
    background: white;
  }
}

@media screen and (min-height: 720px) {
  .form-elements {
    padding: 10px;
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 12px 15px;
    border-radius: 5px;
    background: white;
  }
}