.cylinderContainer {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  padding: 10px;
  flex-wrap: wrap;
}

.cylinder {
  width: 100%;
  aspect-ratio: 1 / 1;
  min-width: 150px;
  border-radius: 20px;
}
