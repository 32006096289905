.select-wrap {
  position: relative;
  height: 100%;
  text-align: center;
  overflow: hidden;
  font-size: 20px;
  color: #ddd;
  background-color: white;
}

.select-wrap:before,
.select-wrap:after {
  position: absolute;
  z-index: 10000;
  z-index: 1;
  display: block;
  content: "";
  width: 100%;
  height: 50%;
}

.select-wrap:before {
  top: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(1, 1, 1, 0));
}

.select-wrap:after {
  bottom: 0;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(1, 1, 1, 0));
}

.select-options {
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  transform-style: preserve-3d;
  margin: 0 auto;
  display: block;
  transform: translateZ(-150px) rotateX(0deg);
  -webkit-font-smoothing: subpixel-antialiased;
  color: #7e7e7e;
}

.select-option {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: bold;
}
.select-option {
  font-size: 28px;
  width: 80%;
}

.highlight {
  position: absolute;
  z-index: 10000;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font-size: 28px;
  overflow: hidden;
}
.highlight > ul > li {
  font-size: 32px;
  width: 80%;
}

.highlight-list {
  position: absolute;
  z-index: 10000;
  width: 100%;
  margin-top: -0.5px;
  margin-left: 18px;
  padding: 0;
  list-style-type: none;
}

.highlight-item {
  color: rgb(0, 24, 46);
  height: 50px;
  font-weight: bold;
}

.date-selector {
  position: absolute;
  z-index: 10000;
  bottom: 0;
  perspective: 2000px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 300px;
}

.date-selector > div {
  flex: 1;
}

.datePickerActions {
  position: absolute;
  z-index: 10000;
  bottom: 300px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 20px;
  box-sizing: border-box;
  align-items: center;
  color: rgb(0, 24, 46);
  background-color: white;
  border-bottom: 1px solid rgb(0, 24, 46);
}
.datePickerActions p {
  font-size: 26px;
}
