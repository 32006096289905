.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  color: #00A0E1;
}

.modal-main p {
  color: #00A0E1;
  text-align: center;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

#button {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 15px 0px;
  text-align: center;
}