html,
body,
#root,
#root>div {
  height: 100%;
  margin: 0;
}

* {
  font-size: large;
}

@font-face {
  font-family: lindeFontReg;
  src: url('../src/assets/font/LindeDaxRegWeb.woff');
}

@font-face {
  font-family: lindeFontMed;
  src: url('../src/assets/font/LindeDaxMedWeb.woff');
}