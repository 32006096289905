.errorMessage {
  font-size: 15px;
  color: red;
  position: absolute;
  bottom: -7px;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
}

.zipCode {
  letter-spacing: 1em;
  border: 0px solid;
}

input:focus {
  outline: none;
}
input::placeholder {
  color: #2a2b2b; 
  /* color: gray;  */
}
input:disabled::placeholder{
  color: #2a2b2b; 
}
input:disabled{
  color: #2a2b2b; 
}

#buttons {
  margin-top: 30px;
  bottom: 35px;
  width: 100%;
}
.cylinderCount{
  /* max-width: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-height: 647px) {

  #toolbar {
    min-height: 7.5vh;
  }

  #logo img {
    height: 4.5vh;
  }

  #subtitle {
    margin-top: -2ch;
  }

  #subtitle p {
    color: rgb(1, 34, 65);
    font-size: 3.2ch;
  }

  .form-container {
    height: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }

}

@media screen and (min-height: 647px) {

  #toolbar {
    --min-height: 9vh;
  }

  #logo img {
    height: 6vh;
  }

  .form-container {
    height: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }


  #subtitle p {
    color: rgb(1, 34, 65);
    font-size: 3.2ch;
  }



  .error-message {
    color: var(--ion-color-danger);
    font-size: 1.5ch;
  }

  .error-field {
    margin-top: 1ch;
  }

}

@media screen and (min-height: 672px) {
  #toolbar {
    --min-height: 9vh;
  }

  #logo img {
    height: 6vh;
  }

  .form-container {
    height: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }


}

@media screen and (min-height: 690px) {

  #toolbar {
    --min-height: 9vh;
  }

  #logo img {
    height: 6vh;
  }

  .form-container {
    height: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }



  #subtitle p {
    color: rgb(1, 34, 65);
    font-size: 3.5ch;
  }



}

@media screen and (min-height: 720px) {
  #toolbar {
    --min-height: 9vh;
  }

  #logo img {
    height: 6vh;
  }

  .form-container {
    height: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }


  #subtitle p {
    color: rgb(1, 34, 65);
    font-size: 3.5ch;
  }




}