.form-elements {
  position: relative;
}

@media screen and (max-height: 647px) {
  .form-elements {
    padding: 10px;
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 10px 15px;
    border-radius: 5px;
    background: white;
  }

  .form-element {
    justify-self: center;
  }

}

@media screen and (min-height: 647px) {
  .form-elements {
    padding: 10px;
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 10px 15px;
    border-radius: 5px;
    background: white;
  }

  .form-element {
    justify-self: center;
  }
}

@media screen and (min-height: 672px) {
  .form-elements {
    padding: 10px;
    /* height: 13.5ch; */
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 20px 15px;
    border-radius: 5px;
    background: white;
  }

  .form-element {
    justify-self: center;
  }
}

@media screen and (min-height: 690px) {
  .form-elements {
    padding: 10px;
    /* height: 13.5ch; */
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 20px 15px;
    border-radius: 5px;
    background: white;
  }

  .form-element {
    justify-self: center;
  }
}

@media screen and (min-height: 720px) {
  .form-elements {
    padding: 10px;
    /* height: 13.5ch; */
    box-shadow: 5px 10px 10px rgb(1, 34, 65);
    margin: 20px 15px;
    border-radius: 5px;
    background: white;
  }

  .form-element {
    justify-self: center;
  }
}